import React, { useState } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import BlueSection from '../components/homepage/BlueSection';
import vectorarrow from '../images/vectorarrow.png';
import CustomCarousel from '../components/CustomCarousel';
import LaunchYourBrand from '../components/homepage/LaunchYourBrand';
import { graphql, useStaticQuery } from 'gatsby';
import { isToggleOpen, setOpenToggle } from '../utils/helpers';
import WindowWidth from '../components/WindowWidth';
import ButtonLink from '../components/ButtonLink';
import Banner from '../components/Banner';
import { connect } from 'react-redux';

const Homepageindex = (props) => {
  const [toggle, setToggle] = useState({ id: '', toggle: false });
  const [index, setIndex] = useState(0);

  const width = WindowWidth();

  const data = useStaticQuery(graphql`
    {
      wordpressAcfHomePage(wordpress_id: { eq: 727 }) {
        acf {
          hero_section {
            title
            description
            image {
              source_url
            }
            image_description
            button {
              title
              internal_link
              external_link
              custom_link
            }
          }
          customer_brands {
            image {
              source_url
            }
          }
          how_it_works {
            title
            description
            step_content {
              title
              description
              image {
                source_url
              }
            }
            buttons {
              title
              internal_link
              external_link
              custom_link
            }
          }
          homepage_cards {
            title
            description
            image {
              source_url
            }
          }
          jewelry_designs {
            title
            description
            card {
              image {
                source_url
              }
              description
            }
          }
          all_in_one {
            title
            description
            image {
              source_url
            }
            button {
              title
              internal_link
              external_link
              custom_link
            }
          }
          produce_now {
            title
            description
            description_2
            image {
              source_url
            }
            button {
              title
              internal_link
              external_link
              custom_link
            }
            card {
              card_image {
                source_url
              }
              card_list_items {
                item
              }
            }
          }
          faq {
            title
            faq_items {
              title
              description
            }
          }
        }
      }
    }
  `).wordpressAcfHomePage;

  const all_in_one = data.acf.all_in_one[0];
  const customer_brands = data.acf.customer_brands;
  const faq = data.acf.faq[0];
  const hero_section = data.acf.hero_section[0];
  const homepage_cards = data.acf.homepage_cards;
  const how_it_works = data.acf.how_it_works[0];
  const jewelry_designs = data.acf.jewelry_designs[0];
  const produce_now = data.acf.produce_now;

  const [testToggle, setTestToggle] = useState([]);

  return (
    <>
      {props.profile && !props.profile.answers ? <Banner /> : null}
      <Layout
        light_header={undefined}
        sit_on_top
        className='home'
        noAnswers={props.profile && !props.profile.answers ? true : false}
      >
        <SEO title='Gildform' />
        <div className='homepage-container'>
          <div className='homepage-hero-container'>
            <div className='homepage-hero-main'>
              <div className='homepage-text'>
                <div className='homepage-hero-title'>
                  <span>{hero_section.title}</span>
                </div>
                <div className='homepage-hero-description'>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: hero_section.description,
                    }}
                  />
                </div>
                <div className='hero-button'>
                  <ButtonLink
                    {...hero_section.button[0]}
                    className={'hero-button'}
                    size={'lg'}
                  />
                </div>
              </div>
              <div className='homepage-hero-image'>
                <img src={hero_section.image.source_url} />
                <div className='arrow-wrapper'>
                  <div className='hero-info'>
                    <img src={vectorarrow} />
                    <span
                      className='hero-info-text'
                      dangerouslySetInnerHTML={{
                        __html: hero_section.image_description.split('by')[0],
                      }}
                    />
                    <span className='hero-info-text'>by</span>
                    <span
                      className='hero-names'
                      dangerouslySetInnerHTML={{
                        __html: hero_section.image_description.split('by')[1],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='homepage-brands'>
              {width > 992 ? (
                <div className='brands-text'>
                  <span>PARTNERS AND CREATORS</span>
                  <div className='brand-logos'>
                    {customer_brands.map((brand, index) => (
                      <div className='brand-logo' key={index}>
                        <img src={brand.image.source_url} />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className='brands-text'>
                  <span>PARTNERS AND CREATORS</span>
                  <div className='brand-logos'>
                    <CustomCarousel show={1}>
                      {customer_brands.map((brand, index) => (
                        <div className='brand-logo' key={index}>
                          <img src={brand.image.source_url} />
                        </div>
                      ))}
                    </CustomCarousel>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='homepage-roadmap'>
            <div className='homepage-roadmap-title-wrapper'>
              <div className='homepage-roadmap-title'>
                <span>{how_it_works.title}</span>
              </div>
              <div className='homepage-roadmap-description'>
                <span
                  dangerouslySetInnerHTML={{ __html: how_it_works.description }}
                />
              </div>
            </div>
            <Carousel
              interval={null}
              indicators={false}
              activeIndex={index}
              onSelect={() => { }}
              prevIcon={''}
              nextIcon={''}
            >
              {how_it_works &&
                how_it_works.step_content.map((item, index) => {
                  return (
                    <Carousel.Item>
                      <div className='homepage-roadmap-wrapper'>
                        <div className='homepage-roadmap-image'>
                          <img src={item.image.source_url} />
                        </div>
                        <div className='homepage-roadmap-info'>
                          <div className='milestone-steps'>
                            <span>STEP {index + 1}</span>
                          </div>
                          <div className='milestone-title'>
                            <span
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </div>
                          <div className='milestone-description'>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </div>
                          <div className='milestone-buttons'>
                            <div className='milestone-button'>
                              <ButtonLink {...{ ...how_it_works.buttons[0], custom_link: '/model' }} />
                            </div>
                            <div className='milestone-button-learn-more'>
                              <ButtonLink {...how_it_works.buttons[1]} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
            <div className='milestone-stepper'>
              <Button
                className='carousel-buttons'
                aria-label='Carousel button'
                onClick={() => {
                  setIndex(
                    index === 0
                      ? how_it_works.step_content.length - 1
                      : index - 1
                  );
                }}
              >
                <i className='fas fa-arrow-left' />
              </Button>
              <Button
                className='carousel-buttons'
                aria-label='Carousel button'
                onClick={() => {
                  setIndex(
                    index === how_it_works.step_content.length - 1
                      ? 0
                      : index + 1
                  );
                }}
              >
                <i className='fas fa-arrow-right' />
              </Button>
            </div>
          </div>
          <BlueSection location='home' cards={homepage_cards} />
          <div className='featured-designs'>
            <div className='title-section'>
              <div className='design-title'>
                <span>{jewelry_designs.title}</span>
              </div>
              <div className='design-description'>
                <span
                  dangerouslySetInnerHTML={{
                    __html: jewelry_designs.description,
                  }}
                />
              </div>
            </div>
            <div className='designs-section'>
              <CustomCarousel show={width < 992 ? 1 : 4}>
                {jewelry_designs.card.map((card, index) => (
                  <div className='designer-card' key={index}>
                    <div className='photo-section'>
                      <img src={card.image.source_url} />
                    </div>
                    <div
                      className='design-title'
                      dangerouslySetInnerHTML={{ __html: card.description }}
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </div>
          <div className='partner-section'>
            <div className='partner-info-section'>
              <div className='partner-title'>
                <span>{all_in_one.title}</span>
              </div>
              <div className='partner-description'>
                <span
                  dangerouslySetInnerHTML={{ __html: all_in_one.description }}
                />
              </div>
              <div className='partner-button'>
                <ButtonLink {...all_in_one.button[0]} />
              </div>
            </div>
            <div className='partner-photo-section'>
              <img src={all_in_one.image.source_url} />
            </div>
          </div>
          {produce_now &&
            produce_now.map((content, index) => (
              <LaunchYourBrand
                key={index}
                content={content}
                location={'homepage'}
              />
            ))}

          <div className='faq-section'>
            <div className='faq-title'>
              <span>FAQ</span>
            </div>
            <div className='links'>
              {faq.faq_items.map((faqItem, index) => (
                <div className='single-link'>
                  <div
                    key={index}
                    className='link-click'
                    data-toggle='collapse'
                    data-target={`#link${index}`}
                    role='button'
                    aria-expanded='false'
                    aria-controls={index}
                    onClick={() => {
                      setOpenToggle(
                        { id: index, toggle: !toggle.toggle },
                        testToggle,
                        setTestToggle
                      );
                    }}
                  >
                    <span
                      className={
                        isToggleOpen(index, testToggle)
                          ? 'link-title'
                          : 'link-title-collapsed'
                      }
                    >
                      {faqItem.title}
                    </span>
                    {!isToggleOpen(index, testToggle) ? (
                      <i className='fas fa-plus' />
                    ) : (
                      <i className='fas fa-minus' />
                    )}
                  </div>
                  <div className='collapse' id={`link${index}`}>
                    <div className='link-content'>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: faqItem.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs.configs,
  profile: state.login.auth && state.login.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Homepageindex);
